@tailwind base;
@tailwind components;
@tailwind utilities;

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Hides the scrollbar */
}

/* Hide scrollbar for Firefox */
.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Internet Explorer and Edge */
.hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: none; /* Track color */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #c1c1c1; /* Scrollbar thumb color */
  border-radius: 8px;
  border: 2px solid #f1f1f1; /* Space around the thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #8e8e8e; /* Hover color */
}